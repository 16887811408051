import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { Button, Slide, TextField } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { NextPageContext } from 'next';
import CommonClears from '@/helpers/clears/common.clear';
import SEO from '@/helpers/seo';
import CommonMask from '@/helpers/masks';
import CommonValidators from '@/helpers/validators/common.validators';
import api from '@/services/cardapioFidelity/api';
import ModalInfo from '@/components/ModalInfo';
import HeadComp from '@/components/Head';
import Header from '../Header';
import { setFidelityModalPage } from '@/redux/reducers/cardapioFidelity/cardapioAuthFidelity';
import { DoLoginFidelityCardapio } from '@/services/cardapioFidelity/auth';

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>,
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function Login({ seo }: any) {
	const dispatch = useAppDispatch();
	const { client } = useAppSelector(state => state.authDelivery);
	const { requestError, loadingForm } = useAppSelector(state => state.cardapioAuthFidelity);
	const [showSnack, setShowSnack] = useState(false);
	const [snackMsg, setSnackMsg] = useState('');
	const [cpf, setCpf] = useState('');
	const [phone, setPhone] = useState('');
	const [error, setError] = useState({
		cpf: '',
		phone: '',
	});

	useEffect(() => {
		if (client != null) {
			api.defaults.headers['API-TOKEN'] = `${client.token}`;
		}
	}, [client]);

	useEffect(() => {
		if (cpf.length === 11) {
			const cpfValidator = CommonValidators.isCPFValid(cpf);

			setError(oldState => {
				return { cpf: cpfValidator.error, phone: oldState.phone };
			});

			if (cpfValidator.status) {
			}
		} else {
			setError(oldState => {
				return {
					cpf: '',
					phone: oldState.phone,
				};
			});
		}
	}, [cpf]);

	async function sendForm() {
		await dispatch(
			DoLoginFidelityCardapio({
				cpf: cpf,
				fone: phone,
			}),
		);
	}

	useEffect(() => {
		if (requestError) {
			setSnackMsg(requestError);
			setShowSnack(true);
		}
	}, [requestError]);

	function handleModal(state: boolean) {
		setShowSnack(state);
	}

	return (
		<div>
			{seo && seo.retorno && seo.config_cliente && (
				<HeadComp
					title={seo.config_cliente.empresa || ''}
					img={seo.config_cliente.img_logo || ''}
					facebook={seo.config_cliente.pixel_facebook || ''}
					globalSiteId={seo.config_cliente.global_site_id || ''}
					globalSiteTag={seo.config_cliente.global_site_tag || ''}
				/>
			)}

			<>
				<section id="reservationPage">
					<div className="content">
						<Header />
						<p
							className="mb-4 mt-12 text-lg text-center font-bold"
							style={{
								color: 'var(--second-color)',
							}}>
							Faça login em <br /> sua conta
						</p>
						<div className="form-content">
							<TextField
								value={CommonMask.cpf(cpf)}
								error={!!error.cpf}
								helperText={error.cpf}
								fullWidth
								className="cmb-3"
								label="Informe seu CPF"
								inputMode="numeric"
								required
								inputProps={{
									inputMode: 'numeric',
									pattern: '[0-9]*',
								}}
								variant="outlined"
								onChange={event => {
									if (event.target.value.length <= 14) {
										const value = CommonClears.clearCPF(event.target.value);
										setCpf(value);
									}
								}}
							/>

							<TextField
								value={CommonMask.phone(phone)}
								required
								error={!!error.phone}
								helperText={error.phone}
								fullWidth
								className="field"
								label="Seu telefone"
								InputLabelProps={phone ? { shrink: true } : {}}
								inputMode="numeric"
								inputProps={{
									inputMode: 'numeric',
									pattern: '[0-9]*',
								}}
								variant="outlined"
								onChange={event => {
									if (event.target.value.length <= 15) {
										const value = CommonClears.clearPhone(event.target.value);
										setPhone(value);
									}
								}}
							/>
							<p
								className="mb-4 text-lg text-center font-medium"
								style={{
									color: 'var(--second-color)',
									marginTop: '20px',
								}}>
								Junte-se a nós.
								<Button onClick={() => dispatch(setFidelityModalPage(2))}>
									<strong className="title">Criar uma conta.</strong>
								</Button>
							</p>
						</div>
					</div>
					<footer className="footer">
						<Button
							variant="contained"
							className="btnDefault mt-5"
							onClick={() => {
								sendForm();
							}}>
							{loadingForm ? 'Logando' : 'Continuar'}
						</Button>
					</footer>
				</section>

				<ModalInfo open={showSnack} handler={handleModal} message={snackMsg} />
			</>
		</div>
	);
}

export const getServerSideProps = async (context: NextPageContext) => {
	let seo = await SEO(context);
	return seo;
};
