import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { Button, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SEO from '@/helpers/seo';
import { NextPageContext } from 'next';
import HeadComp from '@/components/Head';
import CommonValidators from '@/helpers/validators/common.validators';
import RegisterFormData from './RegisterFormData';
import ModalInfo from '@/components/ModalInfo';
import Header from '../Header';
import api from '@/services/cardapioFidelity/api';
import { CardapioColaboratorsFidelity, CardapioDoRegisterFidelity } from '@/services/cardapioFidelity/register';
import { setFidelityModalPage } from '@/redux/reducers/cardapioFidelity/cardapioAuthFidelity';

export default function Register({ seo }: any) {
	const dispatch = useAppDispatch();
	const { requestError, loadingForm } = useAppSelector(state => state.cardapioRegisterFidelity);
	const { data } = useAppSelector(state => state.cardapioColaborators);
	const { client } = useAppSelector(state => state.authDelivery);
	const { loading } = useAppSelector(state => state.links);
	const [showSnack, setShowSnack] = useState(false);
	const [snackMsg, setSnackMsg] = useState('');

	useEffect(() => {
		if (client != null) {
			api.defaults.headers['API-TOKEN'] = `${client.token}`;
			dispatch(CardapioColaboratorsFidelity());
		}
	}, [client]);

	const [error, setError] = useState({
		cpf: '',
		email: '',
		nome: '',
		fone: '',
		funcionario: '',
	});
	const [form, setForm] = useState({
		cpf: '',
		email: '',
		nome: '',
		fone: '',
		funcionario: '',
	});

	const handleChange = (value: any, typeName: string) => {
		setForm(oldForm => {
			return { ...oldForm, [typeName]: value };
		});

		switch (typeName) {
			case 'cpf':
				const cpfValidator = CommonValidators.isCPFValid(value as string);
				if (!cpfValidator.status) {
					setError(oldErrors => {
						return { ...oldErrors, cpf: cpfValidator.error };
					});
				} else {
					setError(oldErrors => {
						return { ...oldErrors, cpf: '' };
					});
				}
				break;
			case 'nome':
				const nameValidator = CommonValidators.isNameValid(value as string);
				if (!nameValidator.status) {
					setError(oldErrors => {
						return { ...oldErrors, name: nameValidator.error };
					});
				} else {
					setError(oldErrors => {
						return { ...oldErrors, name: '' };
					});
				}
				break;
			case 'email':
				const emailValidator = CommonValidators.isEmailValid(value as string);
				if (!emailValidator.status) {
					setError(oldErrors => {
						return { ...oldErrors, email: emailValidator.error };
					});
				} else {
					setError(oldErrors => {
						return { ...oldErrors, email: '' };
					});
				}
				break;
			case 'fone':
				const phoneValidator = CommonValidators.isCellphoneValid(value as string);
				if (!phoneValidator.status) {
					setError(oldErrors => {
						return { ...oldErrors, phone: phoneValidator.error };
					});
				} else {
					setError(oldErrors => {
						return { ...oldErrors, phone: '' };
					});
				}
				break;
			default:
				break;
		}
	};

	function handleModal(state: boolean) {
		setShowSnack(state);
	}

	async function sendForm() {
		const actionResult = await dispatch(CardapioDoRegisterFidelity(form));
		const response = actionResult.payload;
		if (response.retorno) {
			dispatch(setFidelityModalPage(1));
		}
	}

	useEffect(() => {
		if (requestError) {
			setSnackMsg(requestError);
			setShowSnack(true);
		}
	}, [requestError]);

	return (
		<>
			{seo && seo.retorno && seo.config_cliente && (
				<HeadComp
					title={seo.config_cliente.empresa || ''}
					img={seo.config_cliente.img_logo || ''}
					facebook={seo.config_cliente.pixel_facebook || ''}
					globalSiteId={seo.config_cliente.global_site_id || ''}
					globalSiteTag={seo.config_cliente.global_site_tag || ''}
				/>
			)}
			{client && client?.retorno ? (
				<>
					<section id="reservationPage">
						<div className="content">
							<Header />
							<p
								className="mb-4 mt-12 text-lg text-center font-medium"
								style={{
									color: 'var(--second-color)',
								}}>
								Inscreva-se no fidelidade
							</p>
							<div className="container">
								<RegisterFormData error={error} form={form} handleChange={handleChange} funcionarios={data?.conteudo!} />
							</div>

							<p
								className="mb-4 text-lg text-center font-medium"
								style={{
									color: 'var(--second-color)',
								}}>
								Já tem conta?
								<Button onClick={() => dispatch(setFidelityModalPage(0))}>
									<strong className="title">Acesse</strong>
								</Button>
							</p>
						</div>
						<footer className="footer">
							<Button
								variant="contained"
								className="btnDefault mt-5"
								onClick={() => {
									sendForm();
								}}
								disabled={loading}>
								{loadingForm ? 'Cadastrando...' : 'Continuar'}
							</Button>
						</footer>
					</section>
					<ModalInfo open={showSnack} handler={handleModal} message={snackMsg} />
				</>
			) : (
				<div className="loadingDefault">
					<CircularProgress className="loading" />
				</div>
			)}
		</>
	);
}

export const getServerSideProps = async (context: NextPageContext) => {
	let seo = await SEO(context);
	return seo;
};
