import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { setOpenFidelidade } from '@/redux/reducers/cardapioFidelity/cardapioAuthFidelity';
import React from 'react';
import { TransitionProps } from '@mui/material/transitions';
import { Dialog, Slide } from '@mui/material';
import Login from './components/Login';
import Pontuacao from './components/Pontuacao';
import Register from './components/registro';


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Fidelidade() {
  const dispatch = useAppDispatch();
  const { fidelityModalpage, openFidelidade } = useAppSelector(state => state.cardapioAuthFidelity);

  return (
    <Dialog
      open={openFidelidade} 
      scroll="paper"
      TransitionComponent={Transition}
      fullScreen
      onClose={() => dispatch(setOpenFidelidade(false))} 
      PaperProps={{
        sx: {
          '@media (orientation: landscape)': {
            width: '745px',
            maxWidth: '100%',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            margin: 'auto',
          },
        },
      }}
    >
      {fidelityModalpage === 0 && <Login />} 
      {fidelityModalpage === 1 && <Pontuacao />} 
      {fidelityModalpage === 2 && <Register /> }
    </Dialog>
  );
}
