import CommonClears from '@/helpers/clears/common.clear';
import CommonMask from '@/helpers/masks';
import { useAppSelector } from '@/redux/hooks';
import { TextField } from '@mui/material';

type FormType = {
	handleChange(value: string | number, fieldName: string): void;
	form: {
		cpf: string;
		email: string;
		nome: string;
		fone: string;
	};
	error: {
		cpf: string;
		email: string;
		nome: string;
		fone: string;
	};
	funcionarios: {
		id: number;
		nome: string;
	}[];
};

export default function RegisterFormData(props: FormType) {
	const { loading } = useAppSelector(state => state.links);
	const { form, error, handleChange, funcionarios } = props;
	return (
		<div className="form-content">
			<h4 className="title pb-2">Dados Pessoais:</h4>
			<TextField
				required
				fullWidth
				className="cmb-3"
				label="Nome completo"
				variant="outlined"
				type="text"
				disabled={loading}
				onChange={event => {
					const value = event.target.value;
					handleChange(value, 'nome');
				}}
			/>

			<TextField
				required
				fullWidth
				className="cmb-3"
				label="Qual seu e-mail"
				inputMode="email"
				inputProps={{
					inputMode: 'email',
				}}
				variant="outlined"
				type="email"
				disabled={loading}
				onChange={event => {
					const value = event.target.value;
					handleChange(value, 'email');
				}}
			/>

			<TextField
				value={CommonMask.cpf(form?.cpf)}
				fullWidth
				className="cmb-3"
				label="Informe seu CPF"
				inputMode="numeric"
				required
				inputProps={{
					inputMode: 'numeric',
					pattern: '[0-9]*',
				}}
				variant="outlined"
				disabled={loading}
				onChange={event => {
					if (event.target.value.length <= 14) {
						const value = CommonClears.clearCPF(event.target.value);
						handleChange(value, 'cpf');
					}
				}}
			/>

			<TextField
				value={CommonMask.phone(form?.fone)}
				required
				fullWidth
				className="cmb-3"
				label="Seu telefone"
				InputLabelProps={form?.fone ? { shrink: true } : {}}
				inputMode="numeric"
				inputProps={{
					inputMode: 'numeric',
					pattern: '[0-9]*',
				}}
				variant="outlined"
				disabled={loading}
				onChange={event => {
					if (event.target.value.length <= 15) {
						const value = CommonClears.clearPhone(event.target.value);
						handleChange(value, 'fone');
					}
				}}
			/>
		</div>
	);
}
